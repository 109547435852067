import React from "react"

import { Link, navigate } from "gatsby"

import { checkoutShippingMethodUpdate } from "../../graphql"
import { AuthContext, CartContext } from "../../store"
import Button from "../common/Button"
import { TYPE_ERROR, toastMessage } from "../common/Toast"
import ShippingMethodCard from "./ShippingMethodCard"

import "./Shipping.scss"

const Shipping = () => {
  const {
    availableShippingMethods,
    checkoutId,
    setSelectedShippingMethod,
    shippingAddress,
    billingAddress,
  } = React.useContext(CartContext)
  const { setLoader } = React.useContext(AuthContext)
  const [selectedShipping, setSelectedShipping] = React.useState("")

  const chooseShipping = async () => {
    if (selectedShipping.length === 0) {
      toastMessage("Please select your shipping method!", TYPE_ERROR)
      return
    }

    const data = {
      checkoutId,
      shippingMethodId: selectedShipping,
    }

    setLoader(true)
    try {
      const response = await checkoutShippingMethodUpdate(data)

      const checkout = response.checkoutShippingMethodUpdate.checkout
      const { shippingMethod, shippingPrice, totalPrice } = { ...checkout }
      const tempSelectedMethod = { shippingMethod, shippingPrice, totalPrice }

      setSelectedShippingMethod(tempSelectedMethod)
      navigate("/payment")
      setLoader(false)
    } catch (error) {
      setLoader(false)
      console.log(error)
    }
  }

  return (
    <section className="shipping">
      <div className="shipping__container">
        <div className="shipping__content">
          <div className="shipping__content__title">Choose shipping method</div>
          <div className="shipping__content__methods">
            {availableShippingMethods.map((method, index) => (
              <ShippingMethodCard
                key={index}
                name={method.name}
                id={method.id}
                price={method.price}
                isSelected={selectedShipping === method.id}
                setSelectedShipping={setSelectedShipping}
              />
            ))}
          </div>
        </div>
        <div className="shipping__details">
          <div className="shipping__details__addresses">
            <div className="shipping__details__addresses__address shipping__details__addresses__shipping-address">
              <h3>Shipping Address</h3>
              <div>
                <span>
                  {shippingAddress.firstName} {shippingAddress.lastName}
                </span>
                <span>{shippingAddress.streetAddress1}</span>
                <span>{shippingAddress.streetAddress2}</span>
                <span>
                  {shippingAddress.city}, {shippingAddress.countryArea},{" "}
                  {shippingAddress.postalCode}
                </span>
              </div>
              <Link to="/checkout">Change</Link>
            </div>
            <div className="shipping__details__addresses__address">
              <h3>Billing Address</h3>
              <div>
                <span>
                  {billingAddress.firstName} {billingAddress.lastName}
                </span>
                <span>{billingAddress.streetAddress1}</span>
                <span>{billingAddress.streetAddress2}</span>
                <span>
                  {billingAddress.city}, {billingAddress.countryArea},{" "}
                  {billingAddress.postalCode}
                </span>
              </div>
            </div>
          </div>
          <div className="shipping__details__proceed">
            <Button color="primary" isBlock onClick={chooseShipping}>
              Proceed to Payment
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Shipping
