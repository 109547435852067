import React, { Fragment, useContext } from "react"

import { navigate } from "gatsby"

import Shipping from "../components/checkout/Shipping"
import SEO from "../components/seo"
import { CartContext } from "../store/cart"

const ShippingPage = () => {
  const { availableShippingMethods } = useContext(CartContext)

  React.useEffect(() => {
    if (!localStorage.token) {
      navigate("/login")
    }

    if (availableShippingMethods.length === 0) {
      navigate("/")
    }
  }, [])

  return (
    <Fragment>
      <SEO title="Shipping" path="/shipping" />
      <Shipping />
    </Fragment>
  )
}

export default ShippingPage
